@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300..900;1,300..900&display=swap')

$body: #fff
$theme: #54302C
$dark: #444
$white: #fff
$light: #ececec
$medium: #444
$lite: #6e6e6e

$button: $theme

$blue: #40BAFF
$violet: #8E4A95
$darkblue: #3f436c
$pink: #EA4E98
$marine: #00AACB
$footer: #44727B
$grey: #cdcdcd
$green: #99b660

$basic: 'Raleway', sans-serif

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
