// Colors
.c
  &-white
    color: $white !important
  &-dark
    color: $dark !important
  &-blue
    color: $blue !important
  &-pink
    color: $pink !important
  &-violet
    color: $violet !important
  &-marine
    color: $marine !important
  &-light
    color: $light !important
  &-lite
    color: $lite !important
  &-theme
    color: darken($theme,10%) !important

.b-none
  border: none !important

// Background Colors
.bg
  &-body
    background-color: $body !important
  &-white
    background-color: $white !important
  &-dark
    background-color: $dark !important
  &-blue
    background-color: $blue !important
  &-marine
    background-color: $marine !important
  &-pink
    background-color: $pink !important
  &-violet
    background-color: $violet !important
  &-light
    background-color: $light !important
  &-theme
    background-color: $theme !important
  &-grey
    background-color: $grey !important

.box
  padding: 2em 4em
  border-radius: 24px
  &-1
    background-color: lighten($theme,65%) !important
  &-2
    background-color: lighten($pink,35%) !important
  &-3
    background-color: lighten($light,3%) !important
